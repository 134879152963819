import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import RenderRoutes from "routes/RenderRoutes";
import { useHistory, Link } from "react-router-dom";
import {
	LeftSidebarLayout,
	Dropdown,
	Icon,
	AuthProvider,
	AuthMachine,
	Loading,
	Modal,
	Button,
} from "common-components";
import { Menu } from "antd";

import { UiMainLayoutHeader, UiMenu, UiUserBox } from "./MainLayout.style";

/**
 * MainLayout
 */
const { useAuthDispatch, useAuthState } = AuthProvider;
const { eventType: AuthEventType } = AuthMachine;
const { SubMenu, Item } = Menu;
const env = process.env.REACT_APP_NODE_ENV;

export const MainLayout = ({ routes = [] }) => {
	const authDispatch = useAuthDispatch();
	const authState = useAuthState();
	const history = useHistory();
	const [subSystemModalState, setSubSystemModalState] = useState(false);
	const { name, identity, account } = authState.context.result || {};
	const list = [
		{
			name: "登出",
			onClick: () => authDispatch(AuthEventType.LoggingOut),
			icon: "logout",
		},
	];
	useEffect(async () => {
		if (!authState.context.result) return;
		const { subSystem } = authState.context.result;
		if (subSystem && subSystem.findIndex((item) => item === "ResourceLibrary") === -1) {
			setSubSystemModalState(true);
		}
	}, [authState.value]);
	return (
		<LeftSidebarLayout
			version={`v ${process.env.REACT_APP_VERSION || "1.0.0"}`}
			header={
				<UiMainLayoutHeader>
					<h1 className="title" onClick={() => history.push("/")}>
						南一數位資源入庫平台
					</h1>
					<Dropdown list={list}>
						{name && (
							<UiUserBox>
								<img src={`/assets/roles/${identity}.png`} alt={identity} />
								{account} | {name}
							</UiUserBox>
						)}
					</Dropdown>
				</UiMainLayoutHeader>
			}
			sidebar={
				<UiMenu>
					<Menu defaultSelectedKeys={["1"]} mode="inline">
						<Item key="publicCode" icon={<Icon name="grid_on" />}>
							<Link to="/publicCode?type=year">共用代碼</Link>
						</Item>
						<Item key="newSubject" icon={<Icon name="format_list_bulleted" />}>
							<Link to="/newSubject">新科目資料</Link>
						</Item>
						<Item key="subject" icon={<Icon name="domain_verification" />}>
							<Link to="/subject">科目資料</Link>
						</Item>
						<SubMenu key="chapter" title="章節資料" icon={<Icon name="list_alt" />}>
							<Menu.Item key="chapter">
								<Link to="/chapter">課本章節</Link>
							</Menu.Item>
						</SubMenu>
						<Item key="product" icon={<Icon name="pageview" type="outLined" />}>
							<Link to="/productSearch">產品查詢</Link>
						</Item>
						{identity === "Admin" && (
							<SubMenu key="authority" title="權限管理" icon={<Icon name="manage_accounts" type="outLined" />}>
								<Menu.Item key="accountAuthority">
									<Link to="/accountAuthorityManagement">帳戶管理</Link>
								</Menu.Item>
								<Menu.Item key="groupAuthority">
									<Link to="/groupAuthorityManagement">群組管理</Link>
								</Menu.Item>
								<Menu.Item key="authorityDefaultSetting">
									<Link to="/authorityDefaultSetting">功能管理</Link>
								</Menu.Item>
							</SubMenu>
						)}

						{!subSystemModalState && (
							<Item key="tiku" icon={<Icon name="eco" type="outLined" />}>
								<a href={`https://question-bank${env !== "release" ? `-${env}` : ""}.oneclass.com.tw/`}>題庫</a>
							</Item>
						)}
						<Item key="onetube" disabled={true} icon={<Icon name="video_library" type="outLined" />}>
							<a href={`https://onetube${env !== "release" ? `-${env}` : ""}.oneclass.com.tw/login`}>影音庫</a>
						</Item>
						<Item key="book" disabled={true} icon={<Icon name="chrome_reader_mode" type="outLined" />}>
							電子書
						</Item>
						<Item key="examScope" icon={<Icon name="event_note" type="outLined" />}>
							<Link to="/examScope">段考範圍</Link>
						</Item>
					</Menu>
				</UiMenu>
			}>
			<Modal
				closable={false}
				visible={subSystemModalState}
				footer={[
					<Button.IconButton
						variant="blue"
						full={false}
						iconName="panorama_fish_eye"
						onClick={() => {
							authDispatch(AuthEventType.LoggingOut);
						}}
						Key="ok">
						確認
					</Button.IconButton>,
				]}>
				帳號無此子系統權限
			</Modal>
			{authState.value === "LoginDone" && !subSystemModalState ? <RenderRoutes routes={routes} /> : <Loading />}
		</LeftSidebarLayout>
	);
};

MainLayout.propTypes = {
	routes: PropTypes.array,
};
