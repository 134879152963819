import React, { useEffect, useMemo, useState } from "react";
import { Button, Select, Input, Table, Pagination, Breadcrumb, Icon } from "common-components";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { setSelectOptions } from "utils/common";
import { versionList, subjectsObj, EDUCATIONAL_LIST } from "constants/index";
import { UiTop, UiActionBox, UiTableTop } from "styles";
import { UiExamScopePage } from "./ExamScopePage.style";
import { deleteExamScope, getExamScope } from "api/chapterExamType/index";
import { format } from "date-fns";
import openNotificationWithIcon from "utils/hooks/useNotification";
import useOptions from "./useOptions";
import UploadFilesModal from "./UploadFilesModal/UploadFilesModal";

const { Header, Body, Row, Item } = Table;

const BREADCRUMBS = [
	{
		icon: "home",
		name: "首頁",
		path: "/",
	},
	{
		icon: "event_note",
		name: `段考範圍`,
		path: `/examScope`,
	},
];

const VERSION_OPTIONS = setSelectOptions([
	{ code: "", name: "所有版本" },
	{ code: "N", name: "南一" },
	{ code: "K", name: "康軒" },
	{ code: "H", name: "翰林" },
]);

const DEFAULT_PAGE_SIZE = 10;

const INITIAL_PAGE = 1;

/**
 * 段考範圍頁面
 */
export const ExamScopePage = () => {
	const { yearOptions, eduOptions, eduSubjectMap } = useOptions();
	const [filters, setFilters] = useState({
		pagenumber: INITIAL_PAGE,
		pagesize: DEFAULT_PAGE_SIZE,
		year: "",
		version: "",
		edu: "",
		subject: "",
		keyword: "",
	});
	const [isUploadFilesModalVisible, setIsUploadFilesModalVisible] = useState(false);

	const subjectOptions = useMemo(
		() => setSelectOptions([{ code: "", name: "所有科目" }].concat(eduSubjectMap?.[filters.edu] || [])),
		[eduSubjectMap, filters.edu],
	);

	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: (_context, event) => getExamScope(event.payload),
		},
	});
	const { chapterExamTypeRecord, total } = state.context?.result?.data || {};

	const handleFilterChange = (key, value) => {
		setFilters((prev) => ({ ...prev, [key]: value }));
	};

	const onShowSizeChange = (currentPage, limit) => {
		handleFilterChange("pagenumber", currentPage);
		handleFilterChange("pagesize", limit);
	};

	const handleSubmit = (overwritePayload = {}) => {
		send(FetchEventType.Fetch, { payload: { ...filters, ...overwritePayload } });
	};

	const downloadFile = (fileUrl) => {
		window.open(fileUrl);
	};

	const deleteScope = async (uid) => {
		await deleteExamScope({ UIDs: [uid] });
		openNotificationWithIcon("success", "刪除成功！");
		handleSubmit();
	};

	useEffect(() => {
		if (!yearOptions?.length) return;
		const year = yearOptions[yearOptions.length - 1].value;
		handleFilterChange("year", year);
		handleSubmit({ year });
	}, [yearOptions]);

	return (
		<UiExamScopePage>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb data={BREADCRUMBS} />
				</div>
				<UiTop>
					<UiActionBox>
						<Select
							options={yearOptions}
							value={filters.year}
							onChange={(value) => handleFilterChange("year", value || "")}
						/>
						<Select
							options={VERSION_OPTIONS}
							value={filters.version}
							onChange={(value) => handleFilterChange("version", value || "")}
						/>
						<Select
							options={eduOptions}
							value={filters.edu}
							onChange={(value) => handleFilterChange("edu", value || "")}
						/>
						<Select
							options={subjectOptions}
							value={filters.subject}
							onChange={(value) => handleFilterChange("subject", value || "")}
						/>
						<Input
							placeholder="搜尋關鍵字"
							value={filters.keyword}
							onChange={(e) => handleFilterChange("keyword", e.target.value)}
						/>
						<Button.IconButton
							variant="blue"
							full={false}
							iconName="search"
							onClick={() => {
								handleFilterChange("pagenumber", INITIAL_PAGE);
								handleSubmit({ pagenumber: INITIAL_PAGE });
							}}>
							查詢
						</Button.IconButton>
					</UiActionBox>
					<div style={{ marginLeft: "auto" }}>
						<Button.IconButton
							variant="blue"
							full={false}
							iconName="cloud_upload"
							onClick={() => setIsUploadFilesModalVisible(true)}>
							匯入
						</Button.IconButton>
					</div>
				</UiTop>
			</UiTableTop>
			<Table margin={20}>
				<Header>
					<Row>
						<Item>學年度</Item>
						<Item>版本</Item>
						<Item>學制</Item>
						<Item>科目</Item>
						<Item flex={3}>檔案名稱</Item>
						<Item flex={1.5}>上傳時間</Item>
						<Item>狀態</Item>
						<Item flex={2}>操作</Item>
					</Row>
				</Header>
				<Body>
					{(chapterExamTypeRecord || []).map((item) => (
						<Row key={item.uid}>
							<Item>{item.year}</Item>
							<Item>{versionList?.[item.version] || item.version}</Item>
							<Item>{EDUCATIONAL_LIST?.[item.edu] || item.edu}</Item>
							<Item>{subjectsObj?.[item.subject] || item.subject}</Item>
							<Item flex={3}>{item.fileName}</Item>
							<Item flex={1.5}>{format(new Date(item.createTime), "yyyy-MM-dd HH:mm")}</Item>
							<Item>{item.message}</Item>
							<Item flex={2}>
								<div className="actions">
									<Button.IconButton
										iconName="file_download"
										full={false}
										textColor="black"
										type="outLined"
										onClick={() => downloadFile(item.fileUrl)}>
										下載
									</Button.IconButton>
									<Button.Popconfirm
										title="確定要刪除？"
										variant="red"
										full={false}
										onConfirm={() => deleteScope(item.uid)}>
										<Icon name="delete" mr="2" />
										刪除
									</Button.Popconfirm>
								</div>
							</Item>
						</Row>
					))}
				</Body>
			</Table>
			{total > DEFAULT_PAGE_SIZE && (
				<Pagination
					defaultCurrent={INITIAL_PAGE}
					current={filters.pagenumber}
					onChange={(value) => {
						handleFilterChange("pagenumber", value);
						handleSubmit({ pagenumber: value });
					}}
					onShowSizeChange={onShowSizeChange}
					total={total}
					defaultPageSize={DEFAULT_PAGE_SIZE}
				/>
			)}
			<UploadFilesModal
				isVisible={isUploadFilesModalVisible}
				setIsVisible={setIsUploadFilesModalVisible}
				refetch={() => {
					handleFilterChange("pagenumber", INITIAL_PAGE);
					handleSubmit({ pagenumber: INITIAL_PAGE });
				}}
			/>
		</UiExamScopePage>
	);
};
