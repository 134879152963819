import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { getBasicSelection } from "api/open/index";
import { setSelectOptions } from "utils/common";
import { useEffect } from "react";

const useOptions = () => {
	const [optionsState, optionsSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const { data, isSuccess } = await getBasicSelection();
				if (!isSuccess) return {};
				const years = data.yearMap.map(({ code }) => code.slice(0, 3));
				return {
					yearOptions: setSelectOptions([...new Set(years)].map((year) => ({ code: year, name: `${year}學年度` }))),
					eduOptions: setSelectOptions([{ code: "", name: "所有學制" }].concat(data.eduMap)),
					eduSubjectMap: data.eduSubject,
				};
			},
		},
	});
	const { yearOptions, eduOptions, eduSubjectMap } = optionsState.context?.result || {};

	useEffect(() => {
		optionsSend(FetchEventType.Fetch);
	}, []);

	return { yearOptions, eduOptions, eduSubjectMap };
};

export default useOptions;
