import React, { useEffect, useMemo, useState, useRef } from "react";
import {
	AuthMachine,
	AuthProvider,
	Button,
	Select,
	Input,
	Table,
	Pagination,
	Modal,
	Breadcrumb,
	Upload,
	Icon,
} from "common-components";
import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";
import openNotificationWithIcon from "utils/hooks/useNotification";
import { v4 as uuidv4 } from "uuid";
import { useMachine } from "@xstate/react";
import {
	getChapterBooks,
	getChapterBooksListSelection,
	importChapter,
	getBooksRelated,
	createChapterBook,
	editChapterBook,
	getChapterSectionRelated,
	deleteChapterSection,
	getChapterBooksRelated,
} from "api/chapter";
import { lockBookMetaBooks } from "api/bookMeta";
import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useHistory } from "react-router-dom";
import { getTime } from "date-fns";
import { DropList, CurriculumTag } from "components";
import { createValidator, required, checkDuplicateName } from "utils/validation";
import { setSelectOptions, sliceTableData, getTableAuthority, exportFile } from "utils/common";
import { POPUP_TITLE, COMMON_TEXT, INIT_AUTHORITY } from "constants/index";
import { UiTop, UiActionBox, UiTableTop, UiLockModal } from "styles";
import useQuery from "utils/hooks/useQuery";
import classnames from "classnames";
import { StickyNoteWrittenSolid } from "@onedesign/icon";
import { Tooltip } from "antd";
import { UiChapterListPage } from "./ChapterListPage.style";

/**
 * 課本章節列表
 */
const { Header, Body, Row, Item } = Table;

const renderNewFiber = (item, systemNow) => {
	const systemNowTime = getTime(new Date(systemNow));
	const itemTime = getTime(new Date(item.createTime));
	return (
		<div key={item.uid} className="haveFiber">
			{item.name}
			{systemNowTime - itemTime < 604800000 && (
				<i
					className={
						classnames("material-icons", "fiber") //7days
					}>
					fiber_new
				</i>
			)}
		</div>
	);
};

const renderKnowledgeList = (knowledgeList, bookInfo, history) => {
	const { eduSubject } = bookInfo;
	return (
		<div>
			{knowledgeList &&
				knowledgeList.map((item) => {
					return (
						<div key={item.code}>
							<span
								className="knowledgeLink"
								onClick={() => {
									history.push(`/chapter/knowledgeDetail?UID=${item.code}&eduSubject=${eduSubject}`);
								}}>
								{item.itemCode}
							</span>{" "}
							| <span className="knowledgeName">{item.itemName}</span>
						</div>
					);
				})}
		</div>
	);
};

const subtraction = (arr1, arr2) => {
	if (!arr1) return [];
	return arr1.filter((e) => arr2.map((item) => item.code).indexOf(e.code) === -1);
};

const defaultDropValue = [{ id: "0", code: "", value: "" }];

const { state: AuthStates } = AuthMachine;
const { useAuthState } = AuthProvider;

export const ChapterListPage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const query = useQuery();
	const code = query.get("code");
	const { upload } = useFirebaseStorage();
	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});
	const [files, setFiles] = useState([]);
	const [lockPopState, setLockPop] = useState(false);
	const [uploadParams, setUploadParams] = useState({});
	const [subject, setSubject] = useState("");
	const onAddHandle = () => {
		const knowledgeList = stateFormParams.knowledge.value;
		let largeNum = 0;
		knowledgeList.forEach((item) => {
			if (parseInt(item.id) >= largeNum) largeNum = parseInt(item.id) + 1;
		});
		const newList = [...knowledgeList, { id: largeNum.toString(), code: "", value: "" }];
		setFormParams({
			...stateFormParams,
			knowledge: {
				value: newList,
			},
		});
	};

	const onDeleteHandle = (id) => {
		const knowledgeList = stateFormParams.knowledge.value;
		const newDropList = knowledgeList.filter((item) => item.id !== id);
		setFormParams({
			...stateFormParams,
			knowledge: {
				value: newDropList,
			},
		});
	};

	const onDropSelectChange = (option, id) => {
		const knowledgeList = stateFormParams.knowledge.value;
		const newDropList = knowledgeList.map((item) => {
			if (item.id === id) {
				if (option) {
					item.value = option.value;
					item.code = option.code;
				} else {
					item.code = "";
					item.value = "";
				}
			}
			return item;
		});
		setFormParams({
			...stateFormParams,
			knowledge: {
				value: newDropList,
			},
		});
	};

	const dropChangeHandle = (value) => {
		setFormParams({
			...stateFormParams,
			knowledge: {
				value,
			},
		});
	};

	const [state, setState] = useState({
		duplicatePayload: {},
		searchValue: "",
	});

	const [selectionParamsState, setSelectionParams] = useState({
		versionValue: "",
		bookValue: "",
		yearValue: "",
	});

	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});

	const [authorityState, setAuthority] = useState(INIT_AUTHORITY);
	const [modalState, setModalState] = useState({
		isDuplicateVisible: false,
		isVisible: false,
		mode: COMMON_TEXT.CREATE,
		type: undefined,
	});

	const [stateFormParams, setFormParams] = useState({});

	const tableRef = useRef(null);

	const [stateChapterBooks, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getChapterBooks(event.bookID || code);
				const { isSuccess, message, systemNow, data } = res;
				const { bookInfo = [], bookName = [], chapters = [], allowAdd = [], lockInfo = {} } = data || {};
				return {
					isSuccess,
					message,
					data:
						chapters.length > 0
							? chapters.map((value) => ({
									...value,
									nameText: value.name,
									name: renderNewFiber(value, systemNow),
									editState: false,
									canDelete: value.hierarchy !== 1 && value.hasKnowledge,
									knowledgeText: renderKnowledgeList(value.knowledgeList, bookInfo, history),
							  }))
							: [],
					bookInfo,
					bookName,
					allowAdd,
					isLock: lockInfo?.isLock || false,
				};
			},
			createData: (_context, event) => {
				return createChapterBook(event.bookID, event.payload);
			},
			updateData: async (_context, event) => {
				let res;
				if (event.apiType === "lock") {
					res = await lockBookMetaBooks(event.bookID, event.payload);
				} else {
					res = await editChapterBook(event.bookID, event.payload);
					if (!res.isSuccess) {
						if (res.systemCode === "0491") {
							const targetList = res.message.trim().split(",") || [];
							const errorTarget = knowledgeMap.filter((knowledge) => targetList.indexOf(knowledge.code) !== -1);
							res.message = `${errorTarget.map((item) => item.value)}，該向度已被其他冊使用，請選擇其他未被使用之向度`;
						}
					}
				}
				return res;
			},
			deleteData: (_context, event) => deleteChapterSection(event.bookID, event.payload),
		},
	});

	const [stateChapterBooksListSelection, sendChapterBooksListSelection] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data, isSuccess, message } = await getChapterBooksListSelection(event.bookID || code);
				const { bookMap, versionMap, yearMap } = data || {};
				return {
					isSuccess,
					message,
					bookMap: setSelectOptions(bookMap),
					versionMap: setSelectOptions(versionMap),
					yearMap: setSelectOptions(yearMap),
				};
			},
		},
	});

	const [stateBooksRelated, sendBooksRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getBooksRelated(event.bookID || code);
				const { isSuccess, message, data } = res;
				const {
					existName = [],
					chapterMap = [],
					indexMap = [],
					examType2Map = [],
					examType3Map = [],
					knowledgeMap = [],
				} = data || {};
				return {
					isSuccess,
					message,
					existName,
					chapterMap: setSelectOptions(chapterMap),
					indexMap: setSelectOptions(indexMap),
					examType2Map: setSelectOptions(examType2Map),
					examType3Map: setSelectOptions(examType3Map),
					knowledgeMap: knowledgeMap.map((item) => {
						return {
							value: item.name,
							code: item.code,
						};
					}),
				};
			},
		},
	});

	const [stateChapterSectionRelated, sendChapterSectionRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getChapterSectionRelated(code, event.chapterUID);
				const { isSuccess, message, data } = res;
				const { existName = [], allowKnowledge = false, indexMap = [], knowledgeMap = [] } = data || {};
				return {
					isSuccess,
					message,
					editExistName: existName || [],
					allowKnowledge: allowKnowledge || false,
					singleIndexMap: setSelectOptions(indexMap) || [],
					singleKnowledgeMap:
						knowledgeMap?.map((item) => {
							return {
								value: item.name,
								code: item.code,
							};
						}) || [],
				};
			},
		},
	});

	const [stateChapterBooksRelated, sendChapterBooksRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_ctx, event) => {
				const { data } = await getChapterBooksRelated(event.year, event.payload);
				return {
					curriculumMap: setSelectOptions(data?.curriculumMap),
				};
			},
		},
	});

	const { data, bookInfo, bookName, allowAdd, isLock } = stateChapterBooks.context.result || [];
	const { bookMap, versionMap, yearMap } = stateChapterBooksListSelection.context.result || {};
	const { existName, chapterMap, knowledgeMap, examType2Map, examType3Map, indexMap } =
		stateBooksRelated.context.result || {};
	const { allowKnowledge, singleIndexMap, singleKnowledgeMap, editExistName } =
		stateChapterSectionRelated.context.result || {};
	const { curriculumMap } = stateChapterBooksRelated.context.result || {};
	const existNameMemo = useMemo(() => {
		if (!stateBooksRelated.matches(FetchStates.Resolved)) return;
		return existName;
	}, [existName]);

	const editExistNameMemo = useMemo(() => {
		if (!stateChapterSectionRelated.matches(FetchStates.Resolved)) return;
		return editExistName;
	}, [editExistName]);

	const singleIndexMapMemo = useMemo(() => {
		if (!stateChapterSectionRelated.matches(FetchStates.Resolved)) return;
		return singleIndexMap;
	}, [singleIndexMap]);

	const singleKnowledgeMapMemo = useMemo(() => {
		if (!stateChapterSectionRelated.matches(FetchStates.Resolved)) return;
		return singleKnowledgeMap;
	}, [singleKnowledgeMap]);

	const bookID = useMemo(() => {
		const { versionValue, bookValue, yearValue } = selectionParamsState;

		return `${yearValue}${versionValue}-${subject}${bookValue}`;
	}, [
		bookInfo?.education,
		bookInfo?.subject,
		selectionParamsState.versionValue,
		selectionParamsState.bookValue,
		selectionParamsState.yearValue,
	]);

	const filterData = useMemo(() => {
		if (!data) return [];
		return data.filter((item) => item.nameText.indexOf(state.searchValue) >= 0);
	}, [data, state.searchValue]);

	const sortBookMap = bookMap?.sort((a, b) => {
		return a.value.localeCompare(b.value);
	});

	const breadcrumbLists = useMemo(() => {
		const result = [
			{
				icon: "home",
				name: "首頁-異動紀錄",
				path: "/",
			},
			{
				icon: "list_alt",
				name: "章節資料-課本章節",
				path: "/chapter",
			},
			{
				icon: "list_alt",
				name: `${bookName || ""}`,
				path: `/chapter/list?code=${code}`,
			},
		];
		return result;
	});

	const lockText = useMemo(() => {
		return `是否要將${bookName}表${isLock ? "進行解鎖" : "進行鎖定"}？`;
	}, [isLock, bookName]);

	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
		tableRef.current.scrollIntoView();
	};

	const rulesModel = {
		create: {
			parentUID: [{ message: "章目欄位為必選", validate: required }],
			code: [{ message: "節次欄位為必選", validate: required }],
			name: [{ message: "節次名稱欄位為必填", validate: required }],
		},
		chapter: {
			name: [{ message: "節次名稱欄位為必填", validate: required }],
		},
		section: {
			code: [{ message: "節次欄位為必選", validate: required }],
			name: [{ message: "節次名稱欄位為必填", validate: required }],
		},
	};

	const onFormPopupToggle = (state, mode, data) => {
		let type = "create";
		if (data) {
			const { nameText, parentUID, examType2, examType3, knowledgeList, uid, parentCode, code, remark } = data;
			type = parentUID ? "section" : "chapter";
			sendChapterSectionRelated(FetchEventType.Fetch, {
				chapterUID: uid,
			});
			setFormParams({
				uid: {
					value: uid,
				},
				name: {
					value: nameText,
				},
				code: {
					value: parentCode || code,
				},
				examType2: {
					value: examType2?.code || "",
				},
				examType3: {
					value: examType3?.code || "",
				},
				knowledge: {
					value: knowledgeList
						? knowledgeList.map((item, index) => {
								return {
									id: index.toString(),
									code: item.code,
									value: item.name,
								};
						  })
						: defaultDropValue,
				},
				remark: {
					value: remark,
				},
			});
		} else {
			setFormParams({
				knowledge: {
					value: defaultDropValue,
				},
			});
		}
		if (!state)
			setFormParams({
				knowledge: {
					value: defaultDropValue,
				},
			});
		setModalState({
			...modalState,
			isVisible: state,
			mode,
			type,
		});
	};

	const onSearchHandle = (event) => {
		const value = event.target ? event.target.value : "";
		setState({
			...state,
			searchValue: value.toString(),
		});
		setPageState({
			...pageState,
			currentPage: 1,
		});
	};

	const onSelectChangeHandle = (value, key) => {
		setSelectionParams({
			...selectionParamsState,
			[key]: value,
		});
	};

	const exportHandle = async () => {
		const url = `${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}/Export`;
		exportFile(url, "get");
	};

	const relatedSearch = () => {
		const path = `/chapter/list?code=${bookID}`.toString();
		setState({
			...state,
			searchValue: "",
		});
		history.push(path);
		send(FetchEventType.Fetch, { bookID });
	};

	const lockTable = (isLock) => {
		const payload = {
			isLock,
		};
		setLockPop(false);
		send(FetchEventType.Update, { bookID, payload, apiType: "lock" });
	};

	const submitDisabled = useMemo(() => {
		let allSubmitDataLength = -1;

		const sendDataLength = Object.keys(stateFormParams).length;
		if (sendDataLength > 0) {
			allSubmitDataLength = Object.keys(rulesModel[modalState.type]).length;

			if (sendDataLength >= allSubmitDataLength) {
				for (const [, value] of Object.entries(stateFormParams)) {
					if (value.message) {
						return true;
					}
				}
				return false;
			}
		}
		return true;
	});

	const onFormChange = (key, value) => {
		setFormParams({
			...stateFormParams,
			[key]: {
				value,
				message: "",
			},
		});
	};

	const setFormFetch = (payload) => {
		modalState.mode === COMMON_TEXT.CREATE
			? send(FetchEventType.Create, { bookID: code, payload })
			: send(FetchEventType.Update, { bookID: code, payload });
		onFormPopupToggle(false);
	};

	const onFormSubmitHandle = () => {
		const inputValues = {
			code: "",
			name: "",
			examType2: "",
			examType3: "",
			knowledge: null,
			remark: "",
		};
		Object.entries(stateFormParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});
		let rules = rulesModel[modalState.type];
		const knowledge = inputValues.knowledge.filter((item) => item.code !== "").map((item) => item.code);

		inputValues.knowledge = knowledge.length < 1 ? null : knowledge;
		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setFormParams({ ...stateFormParams, ...errorGroup });
		if (isPass) return;

		if (modalState.mode === COMMON_TEXT.CREATE) {
			if (knowledge.length < 1) {
				setFormParams({
					...stateFormParams,
					knowledge: {
						value: defaultDropValue,
						message: "請至少選擇一個向度",
					},
				});
				return;
			}
			if (!checkDuplicateName(inputValues.name, existNameMemo)) {
				setFormParams({
					...stateFormParams,
					name: {
						value: "",
						message: "該名稱已被其他資料使用,請輸入其他名稱或繼續儲存",
					},
				});
				return;
			}
		} else if (modalState.mode === COMMON_TEXT.EDIT) {
			if (knowledge.length < 1 && allowKnowledge) {
				setFormParams({
					...stateFormParams,
					knowledge: {
						value: defaultDropValue,
						message: "請至少選擇一個向度",
					},
				});
				return;
			}
			if (!checkDuplicateName(inputValues.name, editExistNameMemo)) {
				setFormParams({
					...stateFormParams,
					name: {
						value: "",
						message: "該名稱已被其他資料使用,請輸入其他名稱或繼續儲存",
					},
				});
				return;
			}
		}
		setFormFetch(inputValues);
	};

	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
	};

	const changeFileList = (value) => {
		setFiles(value);
	};

	const onUploadFormSelectChangeHandle = (value, key) => {
		setUploadParams({
			...uploadParams,
			[key]: {
				value,
				message: "",
			},
		});
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		const rules = {
			curriculum: [{ message: "課綱欄位為必選", validate: required }],
		};
		const inputValues = {
			curriculum: "",
		};
		Object.entries(uploadParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});
		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setUploadParams({ ...uploadParams, ...errorGroup });
		if (isPass) return;

		let result = {};
		setUploadPopup({
			...uploadPopup,
			loading: true,
		});
		await Promise.all(
			files.map(async (file) => {
				const uid = uuidv4();
				const res = await upload(`chapter/${uid}-${file.name}`, file);
				const payload = {
					curriculum: inputValues.curriculum,
					url: res.url,
					fileName: file.name,
				};
				result = await importChapter(code)(payload);
			}),
		);
		if (result.isSuccess) {
			openNotificationWithIcon("success", "上傳成功！");
		} else {
			openNotificationWithIcon("error", `上傳失敗！${result.message}`);
		}
		setUploadPopup({
			visible: false,
			loading: false,
		});
		setFiles([]);
		send(FetchEventType.Fetch, {
			bookID: code,
		});
		// sendRecord(FetchEventType.Fetch);
	};

	useEffect(() => {
		if (!authState.matches(AuthStates.LoginDone)) return;
		if (!code) history.push({ pathname: "/chapter" });

		const { authority, tableAuthority } = getTableAuthority("CHAPTER", authState.context.authority.function.YearBook);
		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		send(FetchEventType.Fetch, {
			bookID: code,
		});
		sendChapterBooksListSelection(FetchEventType.Fetch, {
			bookID: code,
		});
		if (tableAuthority.Edit) {
			sendBooksRelated(FetchEventType.Fetch, {
				bookID: code,
			});
		}
	}, [code, authState.value]);

	useEffect(() => {
		if (!bookInfo || !bookMap || !versionMap || !yearMap || !stateChapterBooks.matches(FetchStates.Resolved)) return;
		setSelectionParams({
			...selectionParamsState,
			versionValue: bookInfo.version,
			bookValue: bookInfo.book,
			yearValue: bookInfo.year,
		});
	}, [stateChapterBooks.value, bookMap, versionMap, yearMap]);
	useEffect(() => {
		if (!bookInfo) return;
		setSubject(`${bookInfo.education}${bookInfo.subject}`);
		if (authorityState.tableAuthority.Import) {
			sendChapterBooksRelated(FetchEventType.Fetch, {
				year: bookInfo.year,
				payload: {
					edu: bookInfo.education,
					subject: bookInfo.subject,
				},
			});
		}
	}, [bookInfo]);

	useEffect(() => {
		if (!curriculumMap) return;
		setUploadParams({
			...uploadParams,
			curriculum: {
				value: curriculumMap[curriculumMap.length - 1].value,
				message: "",
			},
		});
	}, [curriculumMap]);
	return (
		<UiChapterListPage ref={tableRef}>
			<UiTableTop>
				<UiTop>
					<UiActionBox>
						<Button.IconButton
							variant="white"
							full={false}
							iconName="keyboard_backspace"
							onClick={() => {
								history.push(
									`/chapter?year=${bookInfo.year}&education=${bookInfo.education}&subject=${bookInfo.subject}`,
								);
							}}>
							回上層
						</Button.IconButton>
						<Breadcrumb data={breadcrumbLists} />
					</UiActionBox>
					{bookInfo && (
						<UiActionBox>
							<CurriculumTag year={parseInt(bookInfo.curriculum)} />
						</UiActionBox>
					)}
				</UiTop>
				<UiTop>
					<UiActionBox>
						<Select
							options={yearMap}
							value={selectionParamsState.yearValue}
							onChange={(value) => onSelectChangeHandle(value, "yearValue")}
						/>
						<Select
							options={versionMap}
							value={selectionParamsState.versionValue}
							onChange={(value) => onSelectChangeHandle(value, "versionValue")}
						/>
						<Select
							options={sortBookMap}
							value={selectionParamsState.bookValue}
							onChange={(value) => onSelectChangeHandle(value, "bookValue")}
						/>
						<Button.IconButton variant="blue" full={false} iconName="search" onClick={relatedSearch}>
							查詢
						</Button.IconButton>
					</UiActionBox>
					<UiActionBox>
						{/* https://team-oneclass.atlassian.net/browse/RLIB-1 重要功能按鈕暫時隱藏 */}
						{/* {authorityState.tableAuthority.Lock ? (
							<>
								{isLock ? (
									<Button.IconButton
										variant="white"
										full={false}
										iconName="lock_open"
										onClick={() => {
											setLockPop(true);
										}}>
										解鎖
									</Button.IconButton>
								) : (
									<Button.IconButton
										variant="orange"
										full={false}
										iconName="lock"
										onClick={() => {
											setLockPop(true);
										}}>
										鎖定
									</Button.IconButton>
								)}
							</>
						) : (
							""
						)}
						{authorityState.tableAuthority.Edit && allowAdd ? (
							<Button.IconButton
								variant="blue"
								full={false}
								iconName="add"
								onClick={() => onFormPopupToggle(true, COMMON_TEXT.CREATE)}>
								新增
							</Button.IconButton>
						) : (
							""
						)}
						{authorityState.tableAuthority.Import ? (
							<Button.IconButton variant="blue" full={false} iconName="cloud_upload" onClick={toggleUploadPopupVisible}>
								匯入
							</Button.IconButton>
						) : (
							""
						)} */}
						{authorityState.tableAuthority.Export ? (
							<Button.IconButton variant="blue" full={false} iconName="save_alt" onClick={exportHandle}>
								匯出
							</Button.IconButton>
						) : (
							""
						)}
						<div className="search">
							<Input
								placeholder="搜尋..."
								type={"search"}
								value={state.searchValue}
								onChange={(e) => onSearchHandle(e)}
							/>
						</div>
					</UiActionBox>
				</UiTop>
			</UiTableTop>
			<Table margin={20}>
				<Header>
					<Row>
						<Item flex={0.5}>代碼</Item>
						<Item flex={1.5}>名稱</Item>
						<Item className="remarkIcon" flex={0.5}>
							備註
						</Item>
						<Item flex={2}>向度</Item>
						<Item>二段考</Item>
						<Item>三段考</Item>
						{/* https://team-oneclass.atlassian.net/browse/RLIB-1 重要功能按鈕暫時隱藏 */}
						{/* {authorityState.tableAuthority.Edit && !isLock ? <Item flex={2}>編輯</Item> : ""} */}
					</Row>
				</Header>
				<Body isLoading={!stateChapterBooks.matches(FetchStates.Resolved)}>
					{filterData &&
						sliceTableData(filterData, pageState.currentPage, pageState.limit).map((item) => {
							return (
								<Row key={item.code}>
									<Item flex={0.5}>{item.code}</Item>
									<Item flex={1.5}>{item.name}</Item>
									<Item className="remarkIcon" flex={0.5}>
										{item.remark !== null && item.remark !== "" ? (
											<>
												<Tooltip
													placement="right"
													title={item.remark}
													zIndex={100}
													overlayInnerStyle={{ maxHeight: "150px", overflowY: "auto" }}
													color="#4e4e5a">
													<StickyNoteWrittenSolid color="#FFB800" />
												</Tooltip>
											</>
										) : (
											""
										)}
									</Item>
									<Item flex={2}>{item.knowledgeText}</Item>
									<Item>{item.examType2?.name}</Item>
									<Item>{item.examType3?.name}</Item>
									{/* https://team-oneclass.atlassian.net/browse/RLIB-1 重要功能按鈕暫時隱藏 */}
									{/* {authorityState.tableAuthority.Edit && !isLock ? (
										<Item flex={2}>
											<Button.IconButton
												variant="orange"
												mr={2}
												full={false}
												iconName="edit"
												onClick={() => onFormPopupToggle(true, COMMON_TEXT.EDIT, item)}>
												編輯
											</Button.IconButton>
											<Button.Popconfirm
												title="確定要刪除？"
												variant="red"
												full={false}
												onConfirm={() => {
													send(FetchEventType.Delete, { bookID: code, payload: { ChapterUID: item.uid } });
												}}>
												<Icon name="delete" mr="2" />
												刪除
											</Button.Popconfirm>
										</Item>
									) : (
										""
									)} */}
								</Row>
							);
						})}
				</Body>
			</Table>
			<Pagination
				defaultCurrent={1}
				current={pageState.currentPage}
				onChange={pageChange}
				total={filterData.length}
				defaultPageSize={pageState.limit}
			/>
			<Modal
				title={
					<>
						<i className="material-icons">add_box</i>
						{POPUP_TITLE[modalState.mode]}
					</>
				}
				visible={modalState.isVisible}
				confirmLoading={false}
				okText="確定"
				cancelText="取消"
				okButtonProps={{ ...Modal.okButtonProps, disabled: submitDisabled }}
				onOk={onFormSubmitHandle}
				onCancel={() => onFormPopupToggle(false, "")}>
				{modalState.mode === COMMON_TEXT.CREATE && (
					<Select
						label="章次*"
						options={chapterMap}
						onChange={(value) => onFormChange("parentUID", value)}
						value={stateFormParams["parentUID"]?.value || ""}
						message={stateFormParams["parentUID"]?.message || ""}
					/>
				)}
				{(modalState.mode === COMMON_TEXT.CREATE || modalState.type === "section") && (
					<Select
						label="節次*"
						options={modalState.mode === COMMON_TEXT.CREATE ? indexMap : singleIndexMapMemo}
						onChange={(value) => onFormChange("code", value)}
						value={stateFormParams["code"]?.value || ""}
						message={stateFormParams["code"]?.message || ""}
					/>
				)}
				<Input.TextArea
					label="名稱*"
					onChange={(event) => onFormChange("name", event.target.value)}
					value={stateFormParams["name"]?.value || ""}
					message={stateFormParams["name"]?.message || ""}
				/>
				<Select
					label="二段範圍"
					options={examType2Map || []}
					onChange={(value) => onFormChange("examType2", value)}
					value={stateFormParams["examType2"]?.value || ""}
					message={stateFormParams["examType2"]?.message || ""}
				/>
				<Select
					label="三段範圍"
					options={examType3Map || []}
					onChange={(value) => onFormChange("examType3", value)}
					value={stateFormParams["examType3"]?.value || ""}
					message={stateFormParams["examType3"]?.message || ""}
				/>
				<Input.TextArea
					label="備註"
					onChange={(event) => onFormChange("remark", event.target.value)}
					value={stateFormParams["remark"]?.value || ""}
					maxLength={500}
					message={stateFormParams["remark"]?.message || ""}
				/>
				{(modalState.mode === COMMON_TEXT.CREATE || allowKnowledge) && (
					<DropList
						list={stateFormParams["knowledge"]?.value}
						options={subtraction(
							modalState.mode === COMMON_TEXT.CREATE ? knowledgeMap : singleKnowledgeMapMemo,
							stateFormParams["knowledge"]?.value || [],
						)}
						onChange={dropChangeHandle}
						onAdd={onAddHandle}
						onDelete={onDeleteHandle}
						onSelectChange={onDropSelectChange}
						message={stateFormParams["knowledge"]?.message || ""}
					/>
				)}
			</Modal>
			<Modal
				confirmLoading={uploadPopup.loading}
				visible={uploadPopup.visible}
				onCancel={toggleUploadPopupVisible}
				okText={uploadPopup.loading ? "上傳中..." : "確定"}
				onOk={submitFiles}
				title="上傳檔案">
				<Select
					label="課綱"
					options={curriculumMap}
					value={uploadParams.curriculum?.value || ""}
					message={uploadParams.curriculum?.message || ""}
					onChange={(value) => onUploadFormSelectChangeHandle(value, "curriculum")}
				/>
				<Upload
					fileList={files}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳一個Microsoft Excel 檔<br />
							(副檔名 : .xls/.xlsx)
						</>
					}
					acceptFileType=".xls,.xlsx"
				/>
			</Modal>
			<UiLockModal>
				<Modal
					visible={lockPopState}
					footer={[
						<Button.IconButton
							iconName="close"
							variant="white"
							full={false}
							Key="cancel"
							onClick={() => {
								setLockPop(false);
							}}>
							取消
						</Button.IconButton>,
						<Button.IconButton
							variant="blue"
							full={false}
							iconName="panorama_fish_eye"
							onClick={() => {
								lockTable(!isLock);
							}}
							Key="ok">
							確認
						</Button.IconButton>,
					]}>
					<div className="lockModalIcon">
						{isLock ? (
							<Icon name="lock_open" color="#000" type="outLined" />
						) : (
							<Icon name="lock" color="#ffb800" type="outLined" />
						)}
					</div>
					<div className="lockModalContent">
						{lockText}
						<div className="lockModalHint">{isLock ? "解鎖之後編輯可隨意進行修改" : "鎖定之後編輯無法進行修改"}</div>
					</div>
				</Modal>
			</UiLockModal>
		</UiChapterListPage>
	);
};
