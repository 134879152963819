import { Api } from "common-components";

/**
 * 查詢段考範圍
 */
export const getExamScope = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/chapterexamtype/chapterexamtyperecord`, payload);
	return response;
};

/**
 * 刪除段考範圍
 */
export const deleteExamScope = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/chapterexamtype/hide`, payload);
	return response;
};

/**
 * 匯入段考範圍
 */
export const uploadExamScope = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/chapterexamtype/import`, payload);
	return response;
};
