import { Modal, Upload } from "common-components";
import { UiUploadFilesModal } from "./UploadFilesModal.style";
import { useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";
import { uploadExamScope } from "api/chapterExamType/index";
import openNotificationWithIcon from "utils/hooks/useNotification";

const UploadFilesModal = ({ isVisible, setIsVisible, refetch }) => {
	const { upload } = useFirebaseStorage();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);

	const closeModal = () => {
		setIsVisible(false);
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			const payload = await Promise.all(
				selectedFiles.map(async (file) => {
					const uid = uuidv4();
					const res = await upload(`chapterExamType/${uid}/${file.name}`, file);
					return {
						url: res.url,
						fileName: file.name,
					};
				}),
			);
			const result = await uploadExamScope(payload);
			if (result.error === 200) {
				openNotificationWithIcon("success", "檔案已上傳！");
				closeModal();
				refetch();
			} else {
				openNotificationWithIcon("error", "上傳失敗！請檢查檔名格式是否正確");
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<UiUploadFilesModal>
			<Modal
				confirmLoading={isSubmitting}
				visible={isVisible}
				onCancel={closeModal}
				okText={isSubmitting ? "上傳中..." : "確定"}
				onOk={handleSubmit}
				title="上傳段考範圍">
				<Upload
					fileList={selectedFiles}
					onChange={setSelectedFiles}
					prompt="(副檔名 : .xls/.xlsx)"
					acceptFileType=".xls,.xlsx"
					multiple
				/>
				<div className="uploadFileRules">
					<span>
						檔案命名規則：<b>學年度版本學制科目</b>
					</span>
					<span>範例：</span>
					<ul>
						<li>113南一國小國語</li>
						<li>113南一國中地球科學</li>
						<li>113南一高中數學</li>
					</ul>
				</div>
			</Modal>
		</UiUploadFilesModal>
	);
};
UploadFilesModal.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	setIsVisible: PropTypes.func.isRequired,
	refetch: PropTypes.func.isRequired,
};

export default UploadFilesModal;
