import styled from "styled-components";

export const UiExamScopePage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.search {
		min-width: 200px;
	}

	.breadcrumb {
		margin-bottom: 10px !important;

		span {
			display: flex;
			align-items: center;
			color: #fff;

			a {
				color: #fff;
			}

			&:last-child {
				font-size: 18px;
			}
		}
	}

	.actions {
		display: flex;
		align-items: center;
		gap: 4px;
	}
`;
