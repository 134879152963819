import styled from "styled-components";

export const UiUploadFilesModal = styled.div`
	.uploadFileRules {
		display: flex;
		color: rgba(0, 0, 0, 0.45);
		flex-direction: column;

		> ul {
			margin-inline-start: 2em;
			list-style: disc;
		}
	}
`;
